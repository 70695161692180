import * as yup from 'yup';

export const activityEditValidation = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  realization_time: yup
    .string()
    .required('Campo obrigatório')
    .min(10, 'No mínimo 8 caracteres')
    .matches(
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g,
      'Escolha uma data válida (dd/mm/yyyy)'
    ),
});
