import { Container } from './style';

export const ContainerGroupNone = () => {
  return (
    <Container>
      {' '}
      <h2>Nenhum grupo foi encontrado...</h2>
    </Container>
  );
};
