import styled from 'styled-components';

export const Container = styled.section`
  p {
    font-size: var(--font-size-h3);
    line-height: var(--font-line-height-h3);
    color: var(--color-black-light);

    span {
      color: var(--color-secondary);
    }
  }
`;
